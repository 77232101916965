import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Auth.css';
const API_URL = process.env.REACT_APP_API_URL;

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [resetUsername, setResetUsername] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [forgotPasswordError, setForgotPasswordError] = useState('');
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${API_URL}/api/login`, { username, password });

      // Check if token exists before storing
      if (res.data.token) {
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('username', res.data.username); 
        localStorage.setItem('role', res.data.role);

        // Redirect based on role
        if (res.data.role === 'admin') {
          navigate('/admin');
        } else {
          navigate('/field');
        }

        // Refresh the page to apply the new token
        window.location.reload();
      } else {
        setError('Login failed. No token received.');
      }
    } catch (err) {
      setError('Login failed. Please check your username and password.');
      console.error('Error during login:', err);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setForgotPasswordError('');
    setForgotPasswordSuccess('');

    try {
      await axios.post(`${API_URL}/api/forgot-password`, { username: resetUsername, email: resetEmail });
      setForgotPasswordSuccess('A password reset email has been sent.');
    } catch (err) {
      console.error('Forgot password failed:', err);
      setForgotPasswordError('Failed to send reset email. Please check your credentials.');
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-card">
        <div className="logo"></div>

        <form onSubmit={handleSubmit} className="auth-form">
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            required
            className="auth-input"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
            className="auth-input"
          />
          <button type="submit" className="auth-button">Σύνδεση</button>
        </form>
        {error && <p className="auth-error">{error}</p>}

        <button className="forgot-password-link" onClick={() => setShowForgotPassword(true)}>
          Ξέχασα τον κωδικό μου
        </button>

        {showForgotPassword && (
          <>
            <div className="forgot-password-form">
              <h3>Ανάκτηση Κωδικού</h3>
              <form onSubmit={handleForgotPassword} className="forgot-password-fields">
                <input
                  type="text"
                  value={resetUsername}
                  onChange={(e) => setResetUsername(e.target.value)}
                  placeholder="Username"
                  required
                  className="auth-input"
                />
                <input
                  type="email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  placeholder="Email"
                  required
                  className="auth-input"
                />
                <button type="submit" className="auth-button">Αποστολή Email</button>
              </form>
              {forgotPasswordError && <p className="auth-error">{forgotPasswordError}</p>}
              {forgotPasswordSuccess && <p className="auth-success">{forgotPasswordSuccess}</p>}
            </div>
            <p className="auth-message">
              Συμπλήρωσε το username και το Email σου για να μπορέσεις να ανακτήσεις τον κωδικό σου.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
