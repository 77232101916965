import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Login from './components/Login';
import Register from './components/Register';
import Field from './components/Field';
import Home from './components/Home';
import Points from './components/Points';
import TopLineups from './components/TopLineups';
import PlayerStatistics from './components/PlayerStatistics';
import Admin from './components/Admin';
import './App.css';
import 'react-tooltip/dist/react-tooltip.css';
import arisBadge from './images/badges/aris.png';
import stadiumImage from './images/badges/stadium.png';
import UnderConstruction from "./UnderConstruction";
import ResetPassword from './components/ResetPassword';
import aris11Logo from './images/aris11.png';
const API_URL = process.env.REACT_APP_API_URL;
const isUnderConstruction = process.env.REACT_APP_UNDER_CONSTRUCTION==='true'; 




// Header Component
function Header({ showLogos, setIsDragAndDropEnabled, matchDetails }) {
  const location = useLocation();
  const [countdown, setCountdown] = useState('');
  const [username] = useState(() => {
    // Get the username from localStorage if it exists, or default to 'Guest'
    return localStorage.getItem('username') || 'Guest';
  });
  
  const [countdownEndTime, setCountdownEndTime] = useState('');
  const [opponentBadge, setOpponentBadge] = useState('');
   // Function to handle logout
   const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    window.location.href = '/login'; // Redirect to the login page after logout
  };


  useEffect(() => {
    if (!matchDetails) return;

    const { matchTime, opponent } = matchDetails;

    // Load opponent's badge
    const loadBadge = async () => {
      try {
        const opponentNameFormatted = opponent.toLowerCase().replace(/\s+/g, '');
        const badge = await import(`./images/badges/${opponentNameFormatted}.png`);
        setOpponentBadge(badge.default);
      } catch (error) {
        console.error(`Error loading badge for opponent "${opponent}":`, error);
        setOpponentBadge('');
      }
    };

    loadBadge();

    // Countdown Timer logic
    const interval = setInterval(() => {
      const now = new Date();
      const timeDifference = new Date(matchTime) - now;

      if (timeDifference < 0) {
        clearInterval(interval);
        setCountdown("Μπες στα Αποτελέσματα να δεις τι επέλεξαν οι περισσότεροι χρήστες!");
        setIsDragAndDropEnabled(false);
      } else {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);

        const countdownEndTime = new Date(new Date(matchTime).getTime() - 3 * 60 * 60 * 1000);
        const formattedCountdownEndTime = countdownEndTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
        setCountdownEndTime(formattedCountdownEndTime);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [matchDetails, setIsDragAndDropEnabled]);

  if (!showLogos || !matchDetails) return null;

  const { opponent, matchTime, stadium } = matchDetails;
  const matchDateFormatted = new Date(matchTime).toLocaleDateString('el-GR', { day: 'numeric', month: 'long' });
  const matchTimeFormatted = new Date(matchTime).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });

  return (
    <header className="App-header">
      <div className="header-layout">
      <div className="logo-container">
          <img src={aris11Logo} alt="Aris Logo" className="center-logo" />
        </div>
        <h3 className="next-match-text">Επόμενος Αγώνας...</h3>
        <div className="match-info">
          <img src={arisBadge} alt="Aris Badge" className="team-badge" />
          <h2>VS</h2>
          <img src={opponentBadge} alt={`${opponent} Badge`} className="team-badge opponent-badge" />
        </div>
        <div className="stadium-info">
          <img src={stadiumImage} alt="Stadium Icon" className="stadium-icon" />
          <p className="stadium-name">{stadium} - {matchDateFormatted} {matchTimeFormatted}</p>
        </div>
        {location.pathname === '/field' && (
          <div className="lineup-instructions">
            <p>Καλώς ήρθες {username}!</p>
          </div>
        )}
        <div className="countdown">
          <p>Η ψηφοφορία κλείνει σε: {countdown} ({countdownEndTime})</p>
        </div>
     
        {location.pathname === '/field' && (
          
           <div className="bottom-right-buttons">
           <button onClick={() => window.location.href='/top-lineups'}>
             Αποτελέσματα και Βαθμολογία
           </button>
           <button onClick={() => window.location.href='/player-statistics'}>
             Στατιστικά Παικτών
           </button>
           <button onClick={handleLogout} className="logout-button">
            Αποσύνδεση
          </button>

         </div>
       )}

       {location.pathname === '/top-lineups' && (
         <div className="bottom-right-buttons">
           <button onClick={() => window.location.href='/field'}>
             Home
           </button>
           <button onClick={() => window.location.href='/player-statistics'}>
             Στατιστικά Παικτών
           </button>
           <button onClick={handleLogout} className="logout-button">
            Αποσύνδεση
          </button>
         </div>
       )}

       {location.pathname === '/player-statistics' && (
         <div className="bottom-right-buttons">
           <button onClick={() => window.location.href='/field'}>
             Home
           </button>
           <button onClick={() => window.location.href='/top-lineups'}>
             Αποτελέσματα και Βαθμολογία
           </button>
           <button onClick={handleLogout} className="logout-button">
            Αποσύνδεση
          </button>
         </div>
       )}
    
      </div>
    </header>
  );
}

// PageContent Component
function PageContent() {
  const location = useLocation();

  if (location.pathname === '/login') {
    return <p>Καλώς Ήρθες πίσω! Παρακαλώ συνδέσου για να συνεχίσεις.</p>;
  }

  if (location.pathname === '/register') {
    return <p>Φτιάξε το λογαριασμό σου και βοήθα και εσύ!</p>;
  }

  if (location.pathname === '/') {
    return <p>Κάνε σύνδεση ή εγγραφή για να συνεχίσεις.</p>;
  }

  return null;
}

// AppContent component
function AppContent({ currentMatchId, matchDetails }) {
  const location = useLocation();
  const isFieldOrResultsPage = location.pathname === '/field' || location.pathname === '/top-lineups' || location.pathname === '/player-statistics';
  const isHomeLoginRegister = location.pathname === '/' || location.pathname === '/login' || location.pathname === '/register';
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
  const [isDragAndDropEnabled, setIsDragAndDropEnabled] = useState(true);

  useEffect(() => {
    const role = localStorage.getItem('role');
    setIsAdminLoggedIn(role === 'admin');
  }, [location]);

  return (
    <>
      <Header showLogos={isFieldOrResultsPage} setIsDragAndDropEnabled={setIsDragAndDropEnabled} matchDetails={matchDetails} />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/field" element={<><Points /><Field currentMatchId={currentMatchId} isDragAndDropEnabled={isDragAndDropEnabled} /></>} />
          <Route path="/top-lineups" element={<TopLineups />} />
          <Route path="/player-statistics" element={<PlayerStatistics />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} /> 
          {isAdminLoggedIn ? (
            <Route path="/admin" element={<Admin />} />
          ) : (
            <Route path="/admin" element={<Navigate to="/admin" />} />
          )}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

        {isHomeLoginRegister && <PageContent />}
      </div>
    </>
  );
}



function App() {
  const [currentMatchId, setCurrentMatchId] = useState(null);
  const [matchDetails, setMatchDetails] = useState(null);
  console.log(isUnderConstruction);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    window.location.href = '/login'; // Redirect to the login page after logout
  };

  
  // Fetch current match details
  useEffect(() => {
    const fetchCurrentMatch = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/get-current-match`);
        const matchData = response.data;

        if (matchData && matchData.matchId) {
          setCurrentMatchId(matchData.matchId);
          setMatchDetails({
            matchTime: matchData.date,
            opponent: matchData.opponent,
            stadium: matchData.stadium,
            opponentBadge: matchData.opponent.toLowerCase().replace(/\s+/g, '') + '.png',
          });
        } else {
          console.error('No current match found.');
        }
      } catch (error) {
        console.error('Error fetching current match ID:', error);
      }
    };

    fetchCurrentMatch();
  }, []);

  if (isUnderConstruction) {
    return <UnderConstruction />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Router>
        <div className="App">
          <div className="full-page-background"></div>
          <AppContent currentMatchId={currentMatchId} matchDetails={matchDetails} />
        </div>
        <div className="header-right">
            <button onClick={handleLogout} className="logout-button">
                  Αποσύνδεση
                </button>
            </div>
      </Router>
    </DndProvider>
  );
}

export default App;
