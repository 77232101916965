import React from 'react';
import { useDrag } from 'react-dnd';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const Player = ({ name, photo, lastGameRating, averageRating, position, isSubmitted }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'PLAYER',
    item: { name },
    // Disable dragging if lineup is already submitted
    canDrag: () => !isSubmitted,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <>
      <div
        ref={drag}
        style={{
          opacity: isDragging ? 0.5 : 1,
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#ffeb3b',
          margin: '5px 0',
          padding: '5px',
          borderRadius: '5px',
          border: '1px solid black',
          cursor: isSubmitted ? 'not-allowed' : 'pointer',  // Change cursor style when dragging is not allowed
          boxSizing: 'border-box',
          width: '100%',
          position: 'relative',
        }}
        data-tooltip-id={`player-${name}`}
      >
        <img
          src={photo}
          alt={name}
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            marginRight: '10px',
          }}
        />
        <span style={{ fontSize: '14px', color: '#000', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {name}
        </span>

        <span
          style={{
            backgroundColor: '#000',
            borderRadius: '3px',
            padding: '2px 6px',
            marginLeft: '10px',
            fontSize: '12px',
            fontWeight: 'bold',
            color: '#fbc02d',
            opacity: 1,
          }}
        >
          {position}
        </span>
      </div>
      <ReactTooltip id={`player-${name}`} effect="solid">
        <span>Last Game: {lastGameRating || 'N/A'}</span> | <span>Avg: {averageRating || 'N/A'}</span>
      </ReactTooltip>
    </>
  );
};

const Roster = ({ roster, isSubmitted }) => {
  return (
    <div className="roster">
      <h3>Ρόστερ</h3>
      {roster.map((player) => (
        <Player 
          key={player.id} 
          name={player.name} 
          photo={player.photo} 
          lastGameRating={player.lastGameRating} 
          averageRating={player.averageRating} 
          position={player.position} 
          isSubmitted={isSubmitted}  // Pass the isSubmitted flag to Player
        />
      ))}
    </div>
  );
};

export default Roster;
