import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Auth.css'; // Import the CSS for styling
const API_URL = process.env.REACT_APP_API_URL;

function Register() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear any previous errors

    // Validate email format
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      setError('Παρακαλώ βάλε μία πραγματική διεύθυνση email.');
      return;
    }

    try {
      // Make the registration API request
      await axios.post(`${API_URL}/api/register`, { username, email, password });
      alert('Επιτυχής Εγγραφή!');
      navigate('/login');
    } catch (err) {
      console.error('Registration failed:', err);
      
      // Check for specific error messages returned by the server
      if (err.response && err.response.data.message) {
        setError(err.response.data.message); // Set the error message from the server
      } else {
        setError('Η εγγραφή απέτυχε. Παρακαλώ δοκιμάστε ξανά.');
      }
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-card">
        <div className="logo"></div>

        <form onSubmit={handleSubmit} className="auth-form">
          <input
            type="text"
            placeholder="Όνομα Χρήστη"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="auth-input"
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="auth-input"
          />
          <input
            type="password"
            placeholder="Κωδικός"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="auth-input"
          />
          <button type="submit" className="auth-button">Εγγραφή</button>
        </form>
        {error && <p className="auth-error">{error}</p>}
      </div>
    </div>
  );
}

export default Register;
